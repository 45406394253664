import { dew as _Dew } from ".";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.FIRST_KEY_INDEX = void 0;
  exports.FIRST_KEY_INDEX = 1;

  function transformArguments(key, path, by) {
    return ["JSON.NUMMULTBY", key, path, by.toString()];
  }

  exports.transformArguments = transformArguments;

  var _1 = _Dew();

  Object.defineProperty(exports, "transformReply", {
    enumerable: true,
    get: function () {
      return _1.transformNumbersReply;
    }
  });
  return exports;
}