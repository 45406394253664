import { dew as _ARRAPPENDDew } from "./ARRAPPEND";
import { dew as _ARRINDEXDew } from "./ARRINDEX";
import { dew as _ARRINSERTDew } from "./ARRINSERT";
import { dew as _ARRLENDew } from "./ARRLEN";
import { dew as _ARRPOPDew } from "./ARRPOP";
import { dew as _ARRTRIMDew } from "./ARRTRIM";
import { dew as _DEBUG_MEMORYDew } from "./DEBUG_MEMORY";
import { dew as _DELDew } from "./DEL";
import { dew as _FORGETDew } from "./FORGET";
import { dew as _GETDew } from "./GET";
import { dew as _MGETDew } from "./MGET";
import { dew as _NUMINCRBYDew } from "./NUMINCRBY";
import { dew as _NUMMULTBYDew } from "./NUMMULTBY";
import { dew as _OBJKEYSDew } from "./OBJKEYS";
import { dew as _OBJLENDew } from "./OBJLEN";
import { dew as _RESPDew } from "./RESP";
import { dew as _SETDew } from "./SET";
import { dew as _STRAPPENDDew } from "./STRAPPEND";
import { dew as _STRLENDew } from "./STRLEN";
import { dew as _TYPEDew } from "./TYPE";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformNumbersReply = exports.transformRedisJsonNullReply = exports.transformRedisJsonReply = exports.transformRedisJsonArgument = void 0;

  const ARRAPPEND = _ARRAPPENDDew();

  const ARRINDEX = _ARRINDEXDew();

  const ARRINSERT = _ARRINSERTDew();

  const ARRLEN = _ARRLENDew();

  const ARRPOP = _ARRPOPDew();

  const ARRTRIM = _ARRTRIMDew();

  const DEBUG_MEMORY = _DEBUG_MEMORYDew();

  const DEL = _DELDew();

  const FORGET = _FORGETDew();

  const GET = _GETDew();

  const MGET = _MGETDew();

  const NUMINCRBY = _NUMINCRBYDew();

  const NUMMULTBY = _NUMMULTBYDew();

  const OBJKEYS = _OBJKEYSDew();

  const OBJLEN = _OBJLENDew();

  const RESP = _RESPDew();

  const SET = _SETDew();

  const STRAPPEND = _STRAPPENDDew();

  const STRLEN = _STRLENDew();

  const TYPE = _TYPEDew();

  exports.default = {
    ARRAPPEND,
    arrAppend: ARRAPPEND,
    ARRINDEX,
    arrIndex: ARRINDEX,
    ARRINSERT,
    arrInsert: ARRINSERT,
    ARRLEN,
    arrLen: ARRLEN,
    ARRPOP,
    arrPop: ARRPOP,
    ARRTRIM,
    arrTrim: ARRTRIM,
    DEBUG_MEMORY,
    debugMemory: DEBUG_MEMORY,
    DEL,
    del: DEL,
    FORGET,
    forget: FORGET,
    GET,
    get: GET,
    MGET,
    mGet: MGET,
    NUMINCRBY,
    numIncrBy: NUMINCRBY,
    NUMMULTBY,
    numMultBy: NUMMULTBY,
    OBJKEYS,
    objKeys: OBJKEYS,
    OBJLEN,
    objLen: OBJLEN,
    RESP,
    resp: RESP,
    SET,
    set: SET,
    STRAPPEND,
    strAppend: STRAPPEND,
    STRLEN,
    strLen: STRLEN,
    TYPE,
    type: TYPE
  };

  function transformRedisJsonArgument(json) {
    return JSON.stringify(json);
  }

  exports.transformRedisJsonArgument = transformRedisJsonArgument;

  function transformRedisJsonReply(json) {
    return JSON.parse(json);
  }

  exports.transformRedisJsonReply = transformRedisJsonReply;

  function transformRedisJsonNullReply(json) {
    if (json === null) return null;
    return transformRedisJsonReply(json);
  }

  exports.transformRedisJsonNullReply = transformRedisJsonNullReply;

  function transformNumbersReply(reply) {
    return JSON.parse(reply);
  }

  exports.transformNumbersReply = transformNumbersReply;
  return exports;
}