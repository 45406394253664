var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = exports.FIRST_KEY_INDEX = void 0;
  exports.FIRST_KEY_INDEX = 1;

  function transformArguments(key, path, start, stop) {
    return ["JSON.ARRTRIM", key, path, start.toString(), stop.toString()];
  }

  exports.transformArguments = transformArguments;
  return exports;
}