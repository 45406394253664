import { dew as _Dew } from ".";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.FIRST_KEY_INDEX = void 0;

  const _1 = _Dew();

  exports.FIRST_KEY_INDEX = 1;

  function transformArguments(keys, path) {
    return ["JSON.MGET", ...keys, path];
  }

  exports.transformArguments = transformArguments;

  function transformReply(reply) {
    return reply.map(_1.transformRedisJsonNullReply);
  }

  exports.transformReply = transformReply;
  return exports;
}