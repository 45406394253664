import * as _genericTransformers2 from "@redis/client/dist/lib/commands/generic-transformers";

var _genericTransformers = "default" in _genericTransformers2 ? _genericTransformers2.default : _genericTransformers2;

import { dew as _Dew } from ".";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformReply = exports.transformArguments = exports.IS_READ_ONLY = exports.FIRST_KEY_INDEX = void 0;
  const generic_transformers_1 = _genericTransformers;
  exports.FIRST_KEY_INDEX = 1;
  exports.IS_READ_ONLY = true;

  function transformArguments(key, options) {
    let args = ["JSON.GET", key];

    if (options?.path) {
      args = (0, generic_transformers_1.pushVerdictArguments)(args, options.path);
    }

    if (options?.INDENT) {
      args.push("INDENT", options.INDENT);
    }

    if (options?.NEWLINE) {
      args.push("NEWLINE", options.NEWLINE);
    }

    if (options?.SPACE) {
      args.push("SPACE", options.SPACE);
    }

    if (options?.NOESCAPE) {
      args.push("NOESCAPE");
    }

    return args;
  }

  exports.transformArguments = transformArguments;

  var _1 = _Dew();

  Object.defineProperty(exports, "transformReply", {
    enumerable: true,
    get: function () {
      return _1.transformRedisJsonNullReply;
    }
  });
  return exports;
}