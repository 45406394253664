var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.transformArguments = exports.IS_READ_ONLY = exports.FIRST_KEY_INDEX = void 0;
  exports.FIRST_KEY_INDEX = 1;
  exports.IS_READ_ONLY = true;

  function transformArguments(key, path) {
    const args = ["JSON.STRLEN", key];

    if (path) {
      args.push(path);
    }

    return args;
  }

  exports.transformArguments = transformArguments;
  return exports;
}